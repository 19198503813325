import { sameArray, camelCase, isType, clone, hasPath, setPath } from './utils';
import { getChart } from './getChart';
const utils = {
    sameArray,
    camelCase,
    isType,
    clone,
    hasPath,
    setPath,
};
export { utils, getChart };
